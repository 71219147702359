import React , {useState} from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, Typography, Grid } from "@material-ui/core";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
import  { modules, formats, QuillToolbar } from "../../common";
import RequiredField from "../../Required";
import "react-quill/dist/quill.snow.css";
import { useDispatch, useSelector } from "react-redux";
import { storeData, storeForm } from "../../../slices/mainStore";


const useStyles = makeStyles((theme) => ({

    texteditor:{
        width: "100%",
        margin:" 0 auto",
    },
    
  }));
  
const RichTextBox = (props) => {

    const classes = useStyles(props);
    const dispatch = useDispatch();
    const store = useSelector(storeData);

    let {
        inputVariant,
        duration = false,
        size,
        margin,
        format,
        disabled,
        id,
        required = false,
        topLabel = "Date Of Birth",
        style,
        ...other
    } = props;
const [comments, setComments] = useState("")

    // let modules = {
    //     toolbar: [
    //       [{ 'font': [] }],
    //       [{ 'size': ['small', false, 'large', 'huge'] }],
    //       ['bold', 'italic', 'underline'],
    //       [{'list': 'ordered'}, {'list': 'bullet'}],
    //       [{ 'align': [] }],
    //       [{ 'color': [] }, { 'background': [] }],
    //       ['clean']
    //     ]
    // };

    // let formats = [
    //     'font',
    //     'size',
    //     'bold', 'italic', 'underline',
    //     'list', 'bullet',
    //     'align',
    //     'color', 'background'
    //   ];

const rteChange = (content, delta, source, editor) => {
    dispatch(
        storeForm({
          [sessionStorage.getItem("formId")]: {...store[sessionStorage.getItem("formId")],
            [id]: {
              [props?.topLabel]: {
                value:editor.getHTML(),
                error: false,
                helperText: "",
                required,
              },
            },
          },
        })
      );
      }


    return (
        <Grid item sm={duration ? 12 : 6} {...other}>
            <Grid
                item
                container
                style={{ display: "flex", justifyContent: "flex-start" }}
            >
                <Typography>
                    {topLabel} {required && <RequiredField color={"red"} />}
                </Typography>
                <div className={classes.texteditor}>
                <QuillToolbar />
                <ReactQuill 
                theme="snow"  
                modules={modules}
				        formats={formats} 
                onChange={rteChange}
			           value={store?.[sessionStorage.getItem("formId")]?.[id]?.[props.topLabel]?.value || ''}
                />
                </div>
            </Grid>
        </Grid>
    )
}


export default RichTextBox