import React from "react";
import { Grid, TextField, Typography } from "@material-ui/core";
import RequiredField from "../../Required";
import { useDispatch, useSelector } from "react-redux";
import { storeData, storeForm } from "../../../slices/mainStore";
import { Validation } from "regex-validation";


const CustomText = (props) => {
  let {
    required = false,
    labelAlignment = "left",
    topLabel = "label",
    index,
    style,
    className,
    validation = "",
    id,
    ...other
  } = props;
  const dispatch = useDispatch();
  const store = useSelector(storeData);
  const handleChange = (value) => {
    let validationResult = validation && Validation[validation](value);
    dispatch(
      storeForm({
        [id]: {
          value,
          error:
            Object.keys(validationResult).length !== 0
              ? validationResult.error
              : "",
          helperText:
            Object.keys(validationResult).length !== 0
              ? validationResult.helperText
              : "",
          required,
        },
      })
    );
  };
  return (
    <Grid className={className} item {...other}>
      <Typography
        style={{
          textAlign: labelAlignment,
          width: "100%",
        }}
      >
        {topLabel} {required && <RequiredField color={"red"} />}
      </Typography>
      <TextField
        helperText={store ? store?.[id]?.helperText : ""}
        error={store?.[id]?.error ? store?.[id]?.error : ""}
        value={store?.[id]?.value ? store?.[id]?.value : ""}
        onChange={(e) => handleChange(e.target.value)}
        {...other}
        fullWidth
      ></TextField>
    </Grid>
  );
};

export default CustomText;
