import React from "react";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { ThemeContext } from "./contexts";
const AppTheme = (props) => {
  let [theme, setTheme] = React.useState("");
  const giveMeTheme = () => {
    return createTheme({
      palette: {
        secondary: {
          main: "#2c387e",
        },
      },
    });
  };
  return (
    <>
      <ThemeContext.Provider value={{ ...theme, setTheme }}>
        <ThemeProvider theme={giveMeTheme()}>{props.children}</ThemeProvider>
      </ThemeContext.Provider>
    </>
  );
};
export default AppTheme;
