import React from "react";
import Home from "./screens/home";
import "./App.css";
import { store } from "./store";
import { Provider } from "react-redux";
import AppTheme from "./App.theme";
function App(props) {
  return (
    <Provider store={store}>
      <AppTheme>
        <Home />
      </AppTheme>
    </Provider>
  );
}

export default App;
