import React, { useState } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import RequiredField from "../../Required";
import { Grid, Typography } from "@material-ui/core";

const Multichoice = (props) => {

  let{
    question = "Enter your question",
    stepsHeader,
    ...other
  } =props
  console.log(props)

const getField = (stepIndex)=> {
  return stepsHeader?.map((v,i)=>{
    return(
      <Grid item key={i} {...v}  value={stepIndex}>
        
        <Checkbox/>
        {v.title}
      </Grid>
    )
  })
}
  return (
    <Grid item {...other  }>
      <Grid item container xs={12}>
        <Typography>{question}</Typography>
      </Grid>
      <Grid item container style={{display:'flex', justifyContent:'flex-start'}} >
        <Grid>
        {getField()}
        </Grid>
      </Grid>
    </Grid>
  )
};

export default Multichoice;