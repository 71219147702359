import { createSlice } from "@reduxjs/toolkit";

export const mainStoreSlice = createSlice({
  name: "mainStore",
  initialState: {
    form: {},
  },
  reducers: {
    storeForm: (state, action) => {
      state.form = { ...state.form, ...action.payload };
    },
  },
});

export const { storeForm } = mainStoreSlice.actions;
export const storeData = (state) => state.formData.form;
export default mainStoreSlice.reducer;
