export const queries = {
  getScreen: (showForm) => {
    return {
      db_name: process.env.REACT_APP_DBNAME,
      entity: "screen",
      filter: `screen.metadataid == '${showForm}'`,
      return_fields: "screen",
    };
  },
  getSection: (showForm) => {
    return {
      db_name: process.env.REACT_APP_DBNAME,
      entity: "section",
      filter: `section.metadataid == '${showForm}'`,
      return_fields: "section",
    };
  },
  message_catalogue: () => {
    return {
      db_name: process.env.REACT_APP_DBNAME,
      entity: "message_catalog",
      filter: `message_catalog.metadataid== '${sessionStorage.getItem(
        "formId"
      )}'`,
      return_fields: "message_catalog",
    };
  },
  projectvstools: () => {
    return {
      db_name: process.env.REACT_APP_DBNAME,
      entity: "projectvstools",
      filter: `projectvstools.metadataid=='${sessionStorage.getItem(
        "main_metadata_id"
      )}'`,
      return_fields:
        "merge(keep(projectvstools,'projectid','€€€','clientid'),{projectlang:document(document(projectvstools.projectid).projectlang)})",
    };
  },
  formdetails: (showform) => {
    return {
      db_name: process.env.REACT_APP_DBNAME,
      entity: "form_details",
      filter: `form_details.formMetadataId == '${showform}'`,
      return_fields:
        "{formname:form_details.form,formlayout:form_details.layout,form_metadataid:form_details.formMetadataId,projectvstoolsid:form_details.metadataid}",
    };
  },
};
