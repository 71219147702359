import React from "react";
import { Grid } from "@material-ui/core";
const SectionRender = (props) => {
  let { children, value, index, style, className, ...other } = props;
  return (
    <Grid className={className} item {...other}>
      section
    </Grid>
  );
};
export default SectionRender;
