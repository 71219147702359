const Casbin = require("casbin.js");
const permissionJson = (roleid, maindata, unique) => {
  let pj = maindata;
  let pageJson = pj;
  let Arr = [];
  let access_arr_read = [];
  let access_arr_write = [];
  let access_arr_update = [];
  let access_arr_delete = [];
  let extraRoute = [];
  pageJson.forEach((v) => {
    let repo = {
      rep_name: v.permissionRepo?.rep_name,
      rep_type: v.permissionRepo?.rep_type,
      route_url: v.permissionRepo?.route_url ?? "",
      unique_id: v.permissionRepo?.unique_id,
      projectid: v.permissionRepo?.projectid,
      _id: v?.permissionRepo?._id,
      permsndelete: v?.permsndelete,
      permsnread: v?.permsnread,
      permsnupdate: v?.permsnupdate,
      permsnwrite: v?.permsnwrite,
    };
    Arr.push(repo ?? "");
    if (repo?.permsnread === true) {
      
      access_arr_read.push(repo?.permsnread === true && repo?.unique_id);
    }
    if (repo?.permsnwrite === true) {
      access_arr_write.push(repo?.permsnwrite === true && repo?.unique_id);
    }
    if (repo?.permsnupdate === true) {
      access_arr_update.push(repo?.permsnupdate === true && repo?.unique_id);
    }
    if (repo?.permsndelete === true) {
      access_arr_delete.push(repo?.permsndelete === true && repo?.unique_id);
    }
    if (
      v.permissionRepo?.route_url &&
      v?.permsndelete &&
      v?.permsnread &&
      v?.permsnupdate &&
      v?.permsnwrite
    ) {
      extraRoute.push(v.permissionRepo?.route_url);
    }
  });
  let returnJson = {
    [roleid]: {
      read: access_arr_read,
      write: access_arr_write,
      update: access_arr_update,
      delete: access_arr_delete,
    },
  };

  return returnJson;
};

export const checkWithIdm = (
  maindata,
  roleid,
  unique,
  compareData,
  restOption
) => {
debugger
  
  var permission;
  if (maindata !== 0) {
    
    permission = permissionJson(roleid, maindata, unique);
  }
  //   console.log({ compareData });
  const authorizer = new Casbin.Authorizer("manual");
  authorizer.setPermission(permission[roleid]);
  let isRead = authorizer.can(restOption, compareData);
  return isRead;
};
