import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import RequiredField from "../../Required";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { useDispatch, useSelector } from "react-redux";
import { storeForm, storeData } from "../../../slices/mainStore";
import { KeyboardDateTimePicker } from '@material-ui/pickers'

const Datepicker = (props) => {
  let {
    id,
    inputVariant,
    duration = false,
    size,
    margin,
    format,
    disabled,
    required = false,
    topLabel = "Date picker",
    style,
    isTimeField,
    minDate,
    maxDate,
    defaultDate,
    defaultDatevalue,
    minDatevalue,
    maxDatevalue,
    ...other
  } = props;




  const [maximumDate, setmaximumDate] = React.useState("")
  const [minimumDate, setminimumDate] = React.useState("")
  const [DefaultDate, setdefaultDate] = React.useState("")


  useEffect(() => {
    if (minDate == "Custome") {
      setminimumDate(minDatevalue)
    }

    else if (minDate == "Current") {
      setminimumDate(new Date())
    }

    else if (minDate == "None") {
      setminimumDate(undefined)
    }

    if (maxDate == "Custome") {

      setmaximumDate(maxDatevalue)
    }

    else if (maxDate == "Current") {

      setmaximumDate(new Date())
    }
    else if (maxDate == "None") {

      setmaximumDate(undefined)
    }

    if (defaultDate == "Custome") {

      setdefaultDate(defaultDatevalue)
    }
    else if (defaultDate == "Current") {

      setdefaultDate(new Date())
    }
    else if (defaultDate == "None") {

      setdefaultDate(undefined)
    }

  }, [minDate, maxDate, defaultDate]);

  const dispatch = useDispatch();

  const store = useSelector(storeData);
  const handleDateChange = (changeid, date) => {
    
    dispatch(
      storeForm({
        [sessionStorage.getItem("formId")]: {...store[sessionStorage.getItem("formId")],
          [id]: {
            [changeid]: {
              value:date,
              error: false,
              helperText: "",
              required,
            },
          },
        },
      })
    );
    //console.log(date, changeid);
  };
  //console.log("store", store);

  //console.log("`${format} HH:mm:ss a`" , `${format} HH:mm:ss a`)

  return (
    <Grid item sm={duration ? 12 : 6} {...other}>
      <Grid
        item
        container
        style={{ display: "flex", justifyContent: "flex-start" }}
      >
        <Typography>
          {topLabel} {required && <RequiredField color={"red"} />}
        </Typography>
      </Grid>
      <Grid item container spacing={2}>
        <Grid item>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            {isTimeField ?
              <KeyboardDateTimePicker
                label={duration ? "From" : ""}
                disabled={disabled}
                margin={margin}
                inputVariant={inputVariant}
                size={size}
                format={`${format} hh:mm a`}
                //format={format}
                value={store?.[sessionStorage.getItem("formId")]?.[id]?.[`${id}From`]?.value ? store?.[sessionStorage.getItem("formId")]?.[id]?.[`${id}From`]?.value : null}
                minDate={minimumDate ? minimumDate : undefined}
                maxDate={maximumDate ? maximumDate : undefined}
                onChange={(e, date) => handleDateChange(`${id}From`, date)}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                defaultValue={DefaultDate ? DefaultDate : undefined }
              />

              :
              <KeyboardDatePicker
              label={duration ? "From" : ""}
              disabled={disabled}
              margin={margin}
              inputVariant={inputVariant}
              size={size}
              format={format}
              value={store?.[sessionStorage.getItem("formId")]?.[id]?.[`${id}From`]?.value ? store?.[sessionStorage.getItem("formId")]?.[id]?.[`${id}From`]?.value : null}
              //minDate={new Date()}
                minDate={minimumDate ? minimumDate : undefined}
                maxDate={maximumDate ? maximumDate : undefined}
                onChange={(e, date) => handleDateChange(`${id}From`, date)}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              defaultValue={DefaultDate ? DefaultDate : undefined }
            />

              // <KeyboardDatePicker
              //   label={duration ? "From" : ""}
              //   disabled={disabled}
              //   margin={margin}
              //   inputVariant={inputVariant}
              //   size={size}
              //   format={format}
              //   value={store?.[sessionStorage.getItem("formId")]?.[id]?.[`${id}From`]?.value ? store?.[sessionStorage.getItem("formId")]?.[id]?.[`${id}From`]?.value : undefined}
              //   minDate={minimumDate ? minimumDate : undefined}
              //   maxDate={maximumDate ? maximumDate : undefined}
              //   //defaultValue={DefaultDate ? DefaultDate : undefined}
              //   onChange={(e, date) => handleDateChange(`${id}From`, date)}
              //   KeyboardButtonProps={{
              //     "aria-label": "change date",
              //   }}
              // />


            }
          </MuiPickersUtilsProvider>
        </Grid>
        {duration && (
          <Grid item>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              {isTimeField ?

                <KeyboardDateTimePicker
                  label={duration ? "To" : ""}
                  disabled={disabled}
                  margin={margin}
                  inputVariant={inputVariant}
                  size={size}
                  format={`${format} hh:mm:ss a`}
                  fullWidth
                  value={store?.[`${id}To`] ? store?.[`${id}To`] : null}
                  minDate={minimumDate ? minimumDate : undefined}
                  maxDate={maximumDate ? maximumDate : undefined}
                  //defaultValue={DefaultDate ? DefaultDate : undefined}
                  onChange={(e, date) => handleDateChange(`${id}To`, date)}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  defaultValue={DefaultDate ? DefaultDate : undefined }
                /> :
                <KeyboardDatePicker
                  label={duration ? "To" : ""}
                  disabled={disabled}
                  margin={margin}
                  inputVariant={inputVariant}
                  size={size}
                  format={format}
                  value={store?.[`${id}To`] ? store?.[`${id}To`] : null}
                  minDate={minimumDate ? minimumDate : undefined}
                  maxDate={maximumDate ? maximumDate : undefined}
                  //defaultValue={DefaultDate ? DefaultDate : undefined}
                  onChange={(e, date) => handleDateChange(`${id}To`, date)}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  defaultValue={DefaultDate ? DefaultDate : undefined }
                />
              }
            </MuiPickersUtilsProvider>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default Datepicker;
