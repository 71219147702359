import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Paper } from '@material-ui/core'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import { StylesProvider, createGenerateClassName } from '@material-ui/core'
import { Box } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { Divider } from '@material-ui/core'
//import { SectionRender } from "./sectionRender"
//import { AuthContext } from "../../../contexts";
const useStyles = (props) =>
  makeStyles((theme) => ({
    root: {
      padding: 0,
      width: '100%',
      '& .MuiStepIcon-root.MuiStepIcon-active': {
        color: props?.stepperActiveColor ?? 'red',
      },
      '& .MuiStepIcon-root.MuiStepIcon-completed': {
        color: 'rgba(0, 0, 0, 0.38)',
      },
      '& .MuiStepLabel-label.MuiStepLabel-active': {
        color: props?.stepperActiveTextColor ?? 'red',
      },
      '& .MuiButton-containedPrimary:hover': {
        backgroundColor: props?.nextButtonBackground ?? 'primary',
        color: props?.nextButtonTextColor ?? 'white',
      },
    },

    backButton: {
      marginRight: theme.spacing(1),
      backgroundColor: props?.previousButtonBackground ?? '',
      color: props?.previousButtonTextColor ?? '',
    },
    nextbutton: {
      backgroundColor: props?.nextButtonBackground ?? 'primary',
      color: props?.nextButtonTextColor ?? 'white',
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    header: {
      cursor: 'pointer',
    },
  }))

function ComponentPanel(props) {
  
  const { stepsHeader , com ,sectionData , children, value, index, ...other } = props.props

  if (stepsHeader?.length > 0) {
    
    if (props.activeStep != undefined) {
      
      let v = stepsHeader[props.activeStep];        
      const selectSection = v?.body        
      const component = [com]
      //console.log("component" , component)
      component.forEach(function iter (comppp) {
        const GetChild = sectionData.filter(li => li.componentName === selectSection?.title)
        return comppp["child"] = GetChild[0]?.render?.child;
      })
      
    }
  }
  

  return (
    <div
      role="tabpanel"
      //hidden={value !== props.activeStep}
      id={`simple-tabpanel-${props.activeStep}`}
      aria-labelledby={`simple-tab-${props.activeStep}`}
      {...other}
    >
     <Box sx={{ p: 3 }}>{children}</Box>
    </div>
  )
}

ComponentPanel.propTypes = {
  children: PropTypes.any.isRequired,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}


const PositionBelowStepper = (props) => {
  const classes = useStyles({ ...props })

  const {
    steperTitle,
    stepsHeader,
    value,
    nextLabel,
    alternativeLabel,
    isControlleRequired,
    bodyRequired,
    duration,
    children,
    className,
    sectionData,
    onClick,
    handleStepperClicked,
    com,
    stepperclicked,
    ...other
  } = props

 //console.log("props" , props)
  const [activeStep, setActiveStep] = React.useState(value)
  const [nextStep , setnextStep] = React.useState(false)
  //const auth = React.useContext(AuthContext);
  React.useEffect(() => {
    
    if (!value) {
      setActiveStep(0)
    } else {
      setActiveStep(value)
    }
  }, [value])

  
  React.useEffect(() => {
    
    fetchData()
  }, [nextStep])

  const fetchData = () => {
    handleStepperClicked()
     }
  //to get the Chidren
//console.log("children" , children)
  const getStepContent = (stepIndex) => {      
    if (stepsHeader?.length > 0) {
      if (stepIndex != undefined) {
        let v = stepsHeader[stepIndex];        
        const selectSection = v?.body        
        const cmp = [com]
        cmp.forEach(function iter(component) {
          const GetChild = sectionData.filter(li => li.componentName === selectSection?.title)
          component["child"] = GetChild[0]?.render?.child;
        })
      }
    }

    return (
      <div
        role="tabpanel"
        {...other}
      >
        <Box sx={{ p: 3 }}>{children}</Box>
      </div>
    )
}

//console.log("component" ,component)
  //for handling next
  const handleNext = () => {
    //StepperClicked()
    //console.log("activeStep" , activeStep)
    
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
    getStepContent(activeStep + 1)  
    setnextStep(!nextStep)  
  }
  // for handleCancel
  const handleCancel = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
    getStepContent(activeStep + 1)  
    setnextStep(!nextStep)    
  }
  //for handling previous
  const handleBack = () => {
    //StepperClicked()
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
    getStepContent(activeStep - 1)
    setnextStep(!nextStep)  
  }
  //for handling Reset
  const onTextClick = (index) => {
    setActiveStep(index)
    getStepContent(index)
    setnextStep(!nextStep)  
  }

  return (
    <Grid item sm={duration ? 12 : 6} {...other}>
      {stepsHeader?.length > 0 ? (
        <Divider orientation="vertical" flexItem />
      ) : null}

      <Grid item md={12} xs={12}>
        <Stepper
          activeStep={activeStep}
          alternativeLabel={alternativeLabel}
        >
          {stepsHeader?.map((label, i) => (

            <Step onClick={() => onTextClick(i)} key={label?.header}>
              <StepLabel className={classes.header}>
                {label?.header}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Grid>
      <Grid item md={12}>
        <Grid container justifyContent="center">
          <Grid item md={12}>
            
            <div className={classes.instructions}>
            {getStepContent(activeStep)}
              {/* {<ComponentPanel props={props} activeStep={activeStep}  />} */}
            </div>

          </Grid>
        </Grid>
      </Grid>
      <Grid item md={12} xs={12}>
        {isControlleRequired && (
          <div style={{ marginTop: 20 }}>

            {activeStep !== 0 ? (
              <Button
                disabled={activeStep === 0}
                onClick={handleBack}
                className={classes.backButton}
              >
                {props.previousLabel ?? 'Previous'}
              </Button>
            ) : null}
            <div style={{ float: 'right' }}>
              <Button
                disabled={!stepsHeader[activeStep]?.SkipButton}
                onClick={handleCancel}
                className={classes.backButton}
              >
                {props.cancelLabel ?? 'Skip'}
              </Button>

              {activeStep === stepsHeader?.length - 1 ||
                activeStep === stepsHeader?.length ? (
                <Button
                  className={classes.nextbutton}
                  variant="contained"
                  color={
                    activeStep === stepsHeader?.length - 1
                      ? 'secondary'
                      : 'primary'
                  }
                //onClick={handleSubmit}
                >
                  Save
                </Button>
              ) : (
                <Button
                  className={classes.nextbutton}
                  variant="contained"
                  color={
                    activeStep === stepsHeader?.length - 1
                      ? 'secondary'
                      : 'primary'
                  }
                  onClick={handleNext}
                >
                  {props.nextLabel ?? 'Save and Proceed'}

                </Button>
              )}
            </div>
          </div>
        )}
      </Grid>

    </Grid>

  )
}



export default PositionBelowStepper
