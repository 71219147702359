import React from 'react';
import { AppBar, Grid, Toolbar, Typography } from '@material-ui/core';

function Topnav(props) {
    return (
        <div>
            <Grid container>
                <AppBar position='static' style={{width:"100vw"}}>
                    <Toolbar>
                        <Typography variant="h6">
                            Form Renderer
                        </Typography>
                    </Toolbar>
                </AppBar>
            </Grid>
        </div>
    );
}

export default Topnav;