/**
 * REACT , NPM PACKAGES ARE IMPORTED HERE
 * */
import React from "react";
import { initReactI18next } from "react-i18next";
import { Button, Grid, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import i18n from "i18next";

/**
 * COMPONENTS AND COMMON FUNTIONS ARE IMPORTED HERE
 */

import { ScreenLayout } from "../components/mainFrom/screenLayout";
import Topnav from "../components/navbars/topnav";
import { FormPermission } from "../utils";
import { APICALL } from "../networkcall";
import { queries } from "../ApiQueries/index";
/**
 * MAIN FUNCTION STARTS FROM HERE
 */

function Home() {
  /**
   * REACT HOOKS ARE INITIALIZED HERE
   */

  const [showForm, setShowForm] = React.useState("");
  const [formDetails, setFormDetails] = React.useState([]);
  const [screenData, setScreenData] = React.useState([]);
  const [sectionData, setsectionData] = React.useState([]);
  const [idm_repo, setIdmRepo] = React.useState([]);
  const [langDetail, setLangDetail] = React.useState([]);
  const [langData, setLangData] = React.useState([]);

  React.useEffect(() => {
    i18n.languages = langDetail;
    i18n.use(initReactI18next).init({
      fallbackLng: "English",
      lng: "English",
      resources: langData,
      ns: ["translations"],
      defaultNS: "translations",
    });
  }, [langData, langDetail]);
  console.log("messagecatalog", langData);
  const Get_IDM_Details = async (formDetail) => {
    /**
     * APICALL TAKES THREE PARAMETERS IN THE FOLLOWING ORDER URL,METHOD,BODY
     * eg. APICALL("https://arangodbservice.dev.ainqaplatform.in/api/read_documents","POST",{db_naṁe:"db_name",entity:"entity",return:"db_name"})
     */

    let result = await APICALL(
      process.env.REACT_APP_GETALLPERMISSIONS,
      "POST",
      {
        db_name: "ipmo",
        roleid: "111",
      }
    );
    setIdmRepo(
      result.Result?.[0]?.permission?.[0]?.permissionRepoAccess,
    );
  };
  const handleSubmit = async (e) => {
    /**
     * APICALL TAKES THREE PARAMETERS IN THE FOLLOWING ORDER URL,METHOD,BODY
     * eg. APICALL("https://arangodbservice.dev.ainqaplatform.in/api/read_documents","POST",{db_naṁe:"db_name",entity:"entity",return:"db_name"})
     */

    let response = await APICALL(
      process.env.REACT_APP_READ_DOCUMENT,
      "POST",
      queries.getScreen(showForm)
    );

    let res = await APICALL(
      process.env.REACT_APP_READ_DOCUMENT,
      "POST",
      queries.getSection(showForm)
    );
    setsectionData(res?.result)
   

    const formDetail = await Get_Form_Details();
    await Get_IDM_Details(formDetail);
    // Get_Message_Catalogue_data();
    await Get_Message_Catalogue();
    setScreenData(response?.result);
  };
  const Get_Message_Catalogue_data = async () => {
    /**
     * APICALL TAKES THREE PARAMETERS IN THE FOLLOWING ORDER URL,METHOD,BODY
     * eg. APICALL("https://arangodbservice.dev.ainqaplatform.in/api/read_documents","POST",{db_naṁe:"db_name",entity:"entity",return:"db_name"})
     */

    let response = await APICALL(
      process.env.REACT_APP_READ_DOCUMENT,
      "POST",
      queries.message_catalogue()
    );
    return response.result;
  };
  const Get_Message_Catalogue = async () => {
    /**
     * APICALL TAKES THREE PARAMETERS IN THE FOLLOWING ORDER URL,METHOD,BODY
     * eg. APICALL("https://arangodbservice.dev.ainqaplatform.in/api/read_documents","POST",{db_naṁe:"db_name",entity:"entity",return:"db_name"})
     */

    let result = await APICALL(
      process.env.REACT_APP_READ_DOCUMENT,
      "POST",
      queries.projectvstools()
    );
    /**
     * TODO: CONSTRUCT LANGUAGE DROPDOWN LIST
     */
    const dropDownList = result.result[0].projectlang.reduce(
      (acc, curr) => (acc = [...acc, curr.description]),
      []
    );
    /**
     * TODO: CONSTRUCT TRANSLATION JSON
     */
    const getLangList = result.result[0].projectlang.reduce(
      (acc, curr) =>
        (acc = {
          ...acc,
          [curr.description]: {
            translations: {},
          },
        }),
      {}
    );
    let messageData = await Get_Message_Catalogue_data();
    messageData.forEach((e) =>
      Object.keys(getLangList).forEach((lang) => {
        getLangList[lang].translations = {
          ...getLangList[lang].translations,
          [e.title.toString()]: e.language[lang],
        };
      })
    );
    /**
     * ? SETTING THE CONSTRUCTED LANGUAGE AND TRANSLATION TO THE STATE
     */
    setLangData({ ...getLangList });
    setLangDetail([...dropDownList]);
  };
  const Get_Form_Details = async () => {
    /**
     * APICALL TAKES THREE PARAMETERS IN THE FOLLOWING ORDER URL,METHOD,BODY
     * eg. APICALL("https://arangodbservice.dev.ainqaplatform.in/api/read_documents","POST",{db_naṁe:"db_name",entity:"entity",return:"db_name"})
     */
    let response = await APICALL(
      process.env.REACT_APP_READ_DOCUMENT,
      "POST",
      queries.formdetails(showForm)
    );
    sessionStorage.setItem(
      "main_metadata_id",
      response.result[0].projectvstoolsid
    );
    setFormDetails(response.result[0]);
    return response.result[0];
  };
  return (
    <div style={{ boxSizing: "border-box" }}>
      <Topnav />
      <Grid
        container
        style={{
          marginTop: "25px",
        }}
        alignItems="flex-start"
        spacing={2}
      >
        <Grid item container lg={5} md={5} sm={12} xs={12} spacing={1}>
          <Grid
            container
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            spacing={1}
            alignItems="center"
          >
            <Grid item lg={9} md={9} sm={9} xs={9}>
              <TextField
                fullWidth
                value={showForm}
                variant="outlined"
                size="small"
                onChange={(e) => {
                  setShowForm(e.target.value);
                  sessionStorage.setItem("formId", e.target.value);
                }}
              />
            </Grid>
            <Grid item lg={3} md={3} sm={3} xs={3}>
              <Button
                fullWidth
                onClick={handleSubmit}
                color="primary"
                variant="contained"
                disabled={showForm.length === 0}
              >
                submit
              </Button>
            </Grid>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Autocomplete
              size="small"
              fullWidth
              options={langDetail}
              getOptionLabel={(option) => option}
              onChange={(e, lang) => lang !== null && i18n.changeLanguage(lang)}
              renderInput={(params) => (
                <TextField {...params} label="Language" variant="outlined" />
              )}
            />
          </Grid>
        </Grid>
        
        <Grid item lg={7} md={7} sm={12} xs={12}>
          {screenData?.map((screen, index) => {
            
            return (
              <ScreenLayout
                permission={idm_repo}
                key={screen?.id}
                previewScreen={false}
                screen={screen}
                sectionData={sectionData}
                index={index}
              />
            );
          })}
        </Grid>
      </Grid>
    </div>
  );
}

export default Home;
