import { Grid, TextField } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import RequiredField from "../../Required";
import { useDispatch, useSelector } from "react-redux";
import { storeData, storeForm } from "../../../slices/mainStore";
import { Validation } from "regex-validation";
const Address = (props) => {
  let {
    id,
    variant = "standard",
    disabled,
    required = false,
    topLabel = "Address",
    validation = "",
    style,
    ...other
  } = props;
  const dispatch = useDispatch();
  const store = useSelector(storeData);
  const handleChange = (label, value) => {


    let validationResult = validation && Validation[validation](value);
    console.log(store[sessionStorage.getItem("formId")]);
    dispatch(
      storeForm({
        [sessionStorage.getItem("formId")]: {
          [id]: {
            ...store[sessionStorage.getItem("formId")]?.[id],
            [label]: {
              value,
              error:
                Object.keys(validationResult).length !== 0
                  ? validationResult.error
                  : "",
              helperText:
                Object.keys(validationResult).length !== 0
                  ? validationResult.helperText
                  : "",
              required,
            },
          },
        },
      })
    );
  };
  console.log(store[id]?.Address);
  return (
    <Grid container item {...other} style={style}>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Typography style={{ textAlign: "left" }}>
          {topLabel} {required && <RequiredField color={"red"} />}
        </Typography>
      </Grid>
      <Grid container spacing={2}>
        <Grid item>
          <TextField
            helperText={
              store[sessionStorage.getItem("formId")]?.[id]?.Address?.helperText
                ? store[sessionStorage.getItem("formId")]?.[id]?.Address
                    ?.helperText
                : ""
            }
            error={
              store[sessionStorage.getItem("formId")]?.[id]?.Address?.erro
                ? store[sessionStorage.getItem("formId")]?.[id]?.Address?.error
                : ""
            }
            value={
              store[sessionStorage.getItem("formId")]?.[id]?.Address?.value
                ? store[sessionStorage.getItem("formId")]?.[id]?.Address?.value
                : ""
            }
            onChange={(e) => handleChange("Address", e.target.value)}
            label="Address"
            size="small"
            variant={variant}
            fullWidth
          />
        </Grid>
        <Grid item>
          <TextField
            onChange={(e) => handleChange("Area", e.target.value)}
            label="Area"
            size="small"
            variant={variant}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            onChange={(e) => handleChange("City", e.target.value)}
            label="City"
            size="small"
            variant={variant}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            onChange={(e) => handleChange("State", e.target.value)}
            label="State"
            size="small"
            variant={variant}
            fullWidth
          />
        </Grid>
        <Grid item>
          <TextField
            onChange={(e) => handleChange("Country", e.target.value)}
            label="Country"
            size="small"
            variant={variant}
            fullWidth
          />
        </Grid>
        <Grid item>
          <TextField
            onChange={(e) => handleChange("Zip code", e.target.value)}
            label="Zip code"
            size="small"
            variant={variant}
            fullWidth
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Address;
