import React, { useEffect } from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { Grid, Typography } from '@material-ui/core';
import RequiredField from "../../Required";
import { useDispatch, useSelector } from "react-redux";
import { storeData, storeForm } from "../../../slices/mainStore";
import axios from 'axios';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};



export default function Dropdown(props) {
  // console.log("dropdown",props)
  const dispatch = useDispatch();
  const store = useSelector(storeData);
  let { required = false, duration = false, multiselect = false, checkboxrequired = false, topLabel = "DropDowm", DropDownType = "Static", id, size, ...other } = props;
  const [personName, setPersonName] = React.useState([]);
  const [deptvalue, setDeptvalue] = React.useState([]);
  let value1 = props?.dependent_question_id?.length > 0 &&  props?.dependent_question_id[props?.dependent_question_id?.length -1].title

 
  useEffect(async () => {
    if (DropDownType != "Static") {
      if (props?.endpoint) {

        
        let payload = {}
        if (props?.dependent_question_id?.length > 0) {
           
          props?.dependent_question_id.map(li => {
            let dependent_value = store?.[sessionStorage.getItem("formId")]?.[li?.title]?.value;
            let dependent_label = store?.[sessionStorage.getItem("formId")]?.[li?.title]?.topLabel;
            payload[dependent_label] = dependent_value
          })
           
          var requestOptions = {
            method: "post",
            url: props?.endpoint,
            headers: {
              "Content-Type": "application/json",
            },
            data: payload,
          };
 
          await axios(requestOptions)
            .then((res) => {
              if (res?.data?.response?.length > 0) {
                setDeptvalue(res?.data?.response)
              }

              else {
                setDeptvalue([])
              }

            })
        }
        else {
          // let payload = {
          //   "Country": dependent_value
          // }

          var requestOptions = {
            method: "post",
            url: props?.endpoint,
            headers: {
              "Content-Type": "application/json",
            },
            //data: payload,
          };

          await axios(requestOptions)
            .then((res) => {
              if (res?.data?.response?.length > 0) {
                setDeptvalue(res?.data?.response)
              }

              else {
                setDeptvalue([])
              }

            })
        }

      }

    }
  }, [props?.dependent_question_id?.length > 0 && store?.[sessionStorage.getItem("formId")]?.[value1]?.value])

  const handleChange = (event) => {
    dispatch(
      storeForm({
        [sessionStorage.getItem("formId")]: {
          ...store?.[sessionStorage.getItem("formId")],
          [props?.id]: {
            value: event?.target?.value,
            error: false,
            topLabel: topLabel,
            helperText: "",
            required,
          },
        }
      })
    );
  };

  return (
    <Grid item sm={duration ? 12 : 6} {...other}>


      {DropDownType == "Static" ?
        <FormControl sx={{ m: 1, width: 300 }}>
          <Typography
            style={{
              textAlign: "left",
              width: "100%",
            }}
          >
            {topLabel} {required && <RequiredField color={"red"} />}
          </Typography>

          <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple={multiselect}
            disabled={props?.disabled}
            value={store?.[sessionStorage.getItem("formId")]?.[id]?.value ? store?.[sessionStorage.getItem("formId")]?.[id]?.value : null}
            onChange={handleChange}
            size={"small"}
            input={<OutlinedInput />}
          //renderValue={(selected) => selected.join(', ')}
          //MenuProps={MenuProps}
          >
            {props?.options?.map((name) => (
              <MenuItem key={name?.title} value={name?.title}>
                {checkboxrequired ? <Checkbox checked={personName.indexOf(name?.title) > -1} /> : null}
                <ListItemText primary={name?.title} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        :
        <FormControl sx={{ m: 1, width: 300 }}>
          <Typography
            style={{
              textAlign: "left",
              width: "100%",
            }}
          >
            {topLabel} {required && <RequiredField color={"red"} />}
          </Typography>
          <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple={multiselect}
            disabled={props?.disabled}
            value={store?.[sessionStorage.getItem("formId")]?.[id]?.value ? store?.[sessionStorage.getItem("formId")]?.[id]?.value : null}
            onChange={handleChange}
            input={<OutlinedInput />}
            size={"small"}
          //renderValue={(selected) => selected.join(', ')}
          //MenuProps={MenuProps}
          >
            {deptvalue?.map((name) => (
              <MenuItem key={name?.label} value={name?.value}>
                {checkboxrequired ? <Checkbox checked={deptvalue.indexOf(name?.label) > -1} /> : null}
                <ListItemText primary={name?.label} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>


      }

    </Grid>
  );
}
