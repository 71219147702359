export const APICALL = async (url, method, body) => {
  const myheader = new Headers();
  myheader.append("content-type", "application/json");
  let result = await fetch(url, {
    method,
    headers: myheader,
    body: JSON.stringify(body),
  });
  let response = await result.json();
  return response;
};
