import { Grid, TextField, Typography } from '@material-ui/core';
import React from 'react';
import RequiredField from "../../Required";
import Validation from 'regex-validation';

function MultiText(props) {

    let {
        variant = "standard",
        disabled,
        stepsHeader,
        required = false,
        topLabel = "Multi Text",
        style,
        validation,
        ...other
    } = props;

    const getField = (stepIndex) => {
        return stepsHeader?.map((v, i) => {
            return (
                <Grid item key={i} {...v} value={stepIndex} >
                    <Grid  item spacing={2} style={{paddingTop:'10px'}}>
                        {v.title}
                    </Grid>
                    <Grid item container >
                        <TextField
                            size="small"
                            variant={variant}
                            Validation={validation}
                            fullWidth
                        />
                    </Grid>
                </Grid>
            )
        })
    }

    return (
        <Grid item {...other} >
            <Grid item container xs={12} >
                <Typography>{topLabel} {required && <RequiredField color={"red"} />}</Typography>
            </Grid>
            <Grid item container style={{ display: 'flex', justifyContent: 'flex-start' }} >
                <Grid item lg={12}>
                    {getField()}
                </Grid>
            </Grid>
        </Grid>
    );
}

export default MultiText;