import React from "react";
import { Typography, TextField } from "@material-ui/core";
import RequiredField from "../../Required";
import { useDispatch, useSelector } from "react-redux";
import { storeData, storeForm } from "../../../slices/mainStore";
const FullName = (props) => {
  let {
    prefix = false,
    suffix = false,
    required = false,
    labelAlignment = "left",
    topLabel = "label",
    value,
    index,
    className,
    onChange = () => null,
    ...other
  } = props;
  const dispatch = useDispatch();
  const store = useSelector(storeData);
  const handleChange = (event) => {
    dispatch(
      storeForm({
        [sessionStorage.getItem("formId")]: {...store[sessionStorage.getItem("formId")],
          [props?.id]: {
              value:event?.target?.value,
              error: false,
              helperText: "",
              required,
            },          
        },
      })
    );
  };
  return (
    <div className={`${className} fullnamecontainer`} {...other}>
      {prefix && (
        <div className="fullnameinputbox fullnamefirstlast">
          <Typography
            style={{
              textAlign: labelAlignment,
              width: "100%",
            }}
          >
            {topLabel} {required && <RequiredField color={"red"} />}
          </Typography>
          <TextField
            value={value}
            {...other}
            fullWidth
            onChange={(e) => handleChange(e.target.value)}
          />
        </div>
      )}
      <div className="fullnameinputbox">
        <Typography
          style={{
            textAlign: labelAlignment,
            width: "100%",
          }}
        >
          {topLabel} {required && <RequiredField color={"red"} />}
        </Typography>
        <TextField
          value={value}
          {...other}
          fullWidth
          onChange={(e) => handleChange(e.target.value)}
        />
      </div>
      <div className="fullnameinputbox">
        <Typography
          style={{
            textAlign: labelAlignment,
            width: "100%",
          }}
        >
          {topLabel} {required && <RequiredField color={"red"} />}
        </Typography>
        <TextField
          value={value}
          {...other}
          fullWidth
          onChange={(e) => handleChange(e.target.value)}
        />
      </div>
      <div className="fullnameinputbox">
        <Typography
          style={{
            textAlign: labelAlignment,
            width: "100%",
          }}
        >
          {topLabel} {required && <RequiredField color={"red"} />}
        </Typography>
        <TextField
          value={value}
          {...other}
          fullWidth
          onChange={(e) => handleChange(e.target.value)}
        />
      </div>
      {suffix && (
        <div className="fullnameinputbox fullnamefirstlast">
          <Typography
            style={{
              textAlign: labelAlignment,
              width: "100%",
            }}
          >
            {topLabel} {required && <RequiredField color={"red"} />}
          </Typography>
          <TextField
            value={value}
            {...other}
            fullWidth
            onChange={(e) => handleChange(e.target.value)}
          />
        </div>
      )}
    </div>
  );
};
export default FullName;
