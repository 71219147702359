import React from 'react';
import ReactInputMask from 'react-input-mask';
import { Grid, Typography } from "@material-ui/core";
import RequiredField from "../../Required";


const Maskedinput = (props) => {
    let {
        mask = "",
        maskChar = "",
        alwaysShowMask = "",
        required = false,
        topLabel = "Masked Input",
        style,
        ...other
    } = props
    return (
        <div>
            <Grid item {...other} style={style} >
                <Grid item container style={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <Typography>{topLabel} {required && <RequiredField color={"red"} />}</Typography>
                </Grid>
                <Grid container spacing={1}>
                    <Grid  item>
                        <ReactInputMask
                            mask={mask}
                            maskChar={maskChar}
                            alwaysShowMask={alwaysShowMask}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}

export default Maskedinput;