import Grid from '@material-ui/core/Grid';
import React from 'react';
import { makeStyles } from "@material-ui/core/styles";

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Typography, TextField } from '@material-ui/core';
import RequiredField from "../../Required";
import moment from "moment";
import { Validation } from "regex-validation";
import { useDispatch, useSelector } from "react-redux";
import { storeData, storeForm } from "../../../slices/mainStore";

const useStyles = makeStyles((theme) => ({
  date: {
    "& fieldset": {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      // borderRight:'px solid',
      borderColor: (props) =>
        props?.errorValidation?.error ? "red" : "rgba(0, 0, 0, 0.23)",
    },
  },
  textField: {
    "& fieldset": {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      borderLeft: 0,
      borderColor: (props) =>
        props?.errorValidation?.error ? "red" : "rgba(0, 0, 0, 0.23)",
    },
    "& input": {
      backgroundColor: "rgb(245, 245, 245)",
    },
  },
  labelStyle: {
    fontSize: 16,
  },
  errorText: {
    color: "red",
    fontSize: "0.75rem",
  },
}));


const DOB = (props) => {
  let {
    id,
    inputVariant,
    size,
    margin,
    format,
    disabled,
    required = false,
    topLabel = "Date of Birth",
    style,
    validation,
    ...other
  } = props;


  const dispatch = useDispatch();
  const store = useSelector(storeData);

  const classes = useStyles(props);

  //console.log("props" , props)

  const [selectedDate, setSelectedDate] = React.useState()
  const [dob, setDob] = React.useState({
    age: 0,
    periods: "year",
  });
  const [ageinyears, setAgeinyears] = React.useState(0)
  const [ageinmonths, setAgeinmonths] = React.useState(0)
  const [ageindays, setAgeindays] = React.useState(0)

  // const handleDatechange = (date) => {
  //     setSelectedDate(date);
  //     console.log(date, "test");
  //     var today = new Date();
  //     var age = today.getFullYear() - date.getFullYear();
  //     var month = today.getMonth() - date.getMonth();
  //     var date = today.getDate() - date.getDate();
  //     // if(month<0||(month===0 && today.getDate()<date.getDate())){
  //     //     age--; 
  //     // }
  //     setAgeinyears(age)
  //     setAgeinmonths(month)
  //     setAgeindays(date)
  //      console.log(age,month,date,"month");
  // }

  const findAge = (value) => {

     
    setSelectedDate(value)
    let age;
    let years = moment().diff(value, "years");

    if (years === 0) {
      let months = moment().diff(value, "months");
      if (months === 0) {
        let days = moment().diff(value, "days");
        age = parseFloat("0.0" + days);
        setDob({ age: days, periods: "day" });
      } else {
        age = parseFloat("0." + months);
        setDob({ age: months, periods: "month" });
      }
    } else {
      age = years;
      setDob({ age: years, periods: "year" });
    }

    console.log("props" , props)

    if(props?.agelimit)
    {
      
      let validationResult = validation && Validation[validation](years, props?.MinAge , props?.MaxAge);
      
    dispatch(
      storeForm({
        [sessionStorage.getItem("formId")]: {...store[sessionStorage.getItem("formId")],
          [id]: {
              value,
              error:
                Object.keys(validationResult).length !== 0
                  ? validationResult.error
                  : "",
              helperText:
                Object.keys(validationResult).length !== 0
                  ? validationResult.helperText
                  : "",
              required,
            },
          },
        
      })
    );
    }
    else {  
    // console.log(store[sessionStorage.getItem("formId")]);
    dispatch(
      storeForm({
        [sessionStorage.getItem("formId")]: {...store[sessionStorage.getItem("formId")]?.[id]},
          [id]: {
              value,
              error:false,
              helperText:"",
              required,
          },
      })
    );
    }
  };

  // function Getage (setSelectedDate){
  //     var today = new Date();
  //     var age = today.getFullYear() - setSelectedDate.getFullYear();
  //     var month = today.getMonth() - setSelectedDate.getMonth();
  //     var date = today.getDate() - setSelectedDate.getDate();
  //     console.log(month,"month");

  // };
  const maximumDate = () => {
    return moment(new Date()).format("YYYY-MM-DD");
  };

  

  return (
    <Grid item {...other}>
      <Grid item container style={{ display: 'flex', justifyContent: 'flex-start' }}>
        <Typography>
          {topLabel} {required && <RequiredField color={"red"} />}
        </Typography>
      </Grid>
      
      <Grid item xl={6} md={6} sm={6} xs={12} >
      <TextField
            //onKeyPress={(e) => e.preventDefault()}
            id={props?.id ? props?.id + "Date" : "date"}
            fullWidth
            type={"date"}
            value={store?.[sessionStorage.getItem("formId")]?.[id]?.[props.topLabel]?.value}
           // className={classes.date}
            margin={margin}
            format={format}
            size={size}
            InputLabelProps={{
              shrink: false,
            }}
            inputProps={{
              readOnly: props.isReadonly ?? false,
              max: maximumDate(),
            }}
            
            onChange={(e) => findAge(e.target.value)}
            disabled={props?.disabled ?? false}
            variant={inputVariant}
          />
        {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            id='DoB'
            inputVariant={inputVariant}
            margin={margin}
            format={format}
            size={size}
            value={store?.[sessionStorage.getItem("formId")]?.[id]?.[props.topLabel]?.value ? store?.[sessionStorage.getItem("formId")]?.[id]?.[props.topLabel]?.value : new Date()}
            onChange={findAge}
            maxDate={new Date()}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </MuiPickersUtilsProvider> */}
      </Grid>
      <Grid item xl={6} md={6} sm={6} xs={12} container style={{ display: 'flex', justifyContent: 'flex-start' }}>
        <TextField
          id={props?.id ? props?.id + "Age" : "Age"}
          type={"text"}
          required={props.isrequired}
          fullWidth
          //className={classes.textField}
          variant={inputVariant}
          margin={margin}
            //format={format}
          InputLabelProps={{
            shrink: false,
          }}
          inputProps={{ readOnly: true }}
          size={size}
          disabled={true}
          placeholder="Age"
          //helperText={ store?.[sessionStorage.getItem("formId")]?.[id]?.[props.topLabel]?.helperText ? store?.[sessionStorage.getItem("formId")]?.[id]?.[props.topLabel]?.helperText : ""}
          //error={store?.[sessionStorage.getItem("formId")]?.[id]?.[props.topLabel]?.error ? store?.[sessionStorage.getItem("formId")]?.[id]?.[props.topLabel]?.error : ""}
          // onChange={(e) => props.onChange(e.target.value)}
          value={`${dob?.age >= 0
              ? dob?.age > 1
                ? dob?.age + " " + dob?.periods + "s old"
                : dob?.age + " " + dob?.periods + " old"
              : ""
            }`}
        />
      </Grid>
      
      {store?.[sessionStorage.getItem("formId")]?.[id]?.[props.topLabel]?.error && (
        <div>
          <Typography variant="caption" className={classes.errorText}>
            {store?.[sessionStorage.getItem("formId")]?.[id]?.[props.topLabel]?.helperText}
          </Typography>
        </div>
      )}
      <Grid>
     
      </Grid>
    </Grid>
  );
}


export default DOB;